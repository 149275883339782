import "../scss/app.scss";

// AdminKit (required)
import "./modules/bootstrap";
import "./modules/sidebar";
import "./modules/theme";
import "./modules/feather";

// Charts
import "./modules/chartjs";

// Forms
import "./modules/flatpickr";

// Maps
import "./modules/vector-maps";

document.addEventListener("DOMContentLoaded", () => {

    $('#organisation_select').on('change', function(){
        $.post("/admin/organisations/select", {id: $(this).val()}).done(function(strHtml) {
            $('#site_select').html(strHtml);
        });
    });

    $('#site_select').on('change', function(){
        $.post("/admin/sites/select", {id: $(this).val()}).done(function(strHtml) {
            $('#admin-menu-manage').addClass('in');
            $('#admin-menu-toggle').show();
            window.location = "/admin/pages";
        });
    });

    if ($('#selectedPublication').val() != '')
    {
        $('#admin-menu-manage').addClass('in');
        $('#admin-menu-toggle').show();
    }


});